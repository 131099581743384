import React from "react";
import { useLocation } from "@reach/router";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import "./app-page.css";
import OrganiseListsScreenshot from "./screenshots/organise-lists.png";
import SearchGoogleScreenshot from "./screenshots/search-items.png";
import MultipleListsScreenshot from "./screenshots/multiple-lists.png";
import ViewMapScreenshot from "./screenshots/list-map.png";
import TimelineScreenshot from "./screenshots/timeline.png";
import SearchIncompleteScreenshot from "./screenshots/search-incomplete-items.png";
import MobileOptimizedScreenshot from "./screenshots/mobile-optimized.png";

const ToTheMaxPage = () => {
  const features = [
    {
      title: "Organize lists into folders and subfolders",
      description:
        "I can effortlessly organize my lists into folders and subfolders, providing a clear and hierarchical view of all my tasks. I can track the percentage completion for each level, ensuring I have a comprehensive overview of my progress.",
      image: OrganiseListsScreenshot,
    },
    {
      title: "Search for various items",
      description:
        "I can easily search for and add items to my lists from multiple sources, including Google Places for locations, Ticketmaster for artists, Google Books for reading material, and The Movie Database for films. This feature ensures I can compile exhaustive and diverse lists effortlessly.",
      image: SearchGoogleScreenshot,
    },
    {
      title: "Add the same item to multiple lists",
      description:
        "With ToTheMax, I can add the same item to multiple lists, making it easy to create different lists with crossover items. This ensures consistency and synchronization across all my lists, eliminating the need to manually update each one.",
      image: MultipleListsScreenshot,
    },
    {
      title: "View maps of places visited",
      description:
        "I can visualize all the places in my lists with interactive maps. I can see a map of all the locations included in a specific list, as well as a personal map showcasing all the places I've visited, helping me plan and reflect on my journeys.",
      image: ViewMapScreenshot,
    },
    {
      title: "Timeline of completed items",
      description:
        "I can track my achievements with a detailed timeline of completed items. This feature serves as a motivating tool, encouraging me to complete more tasks and make the most of my weekends and free time by experiencing new activities and places.",
      image: TimelineScreenshot,
    },
    {
      title: "Search for incomplete items near a location",
      description:
        "When traveling to a new place, I can use the search feature to find incomplete items near my location. This ensures I never miss out on activities or places I want to experience, making my trips more fulfilling and organized.",
      image: SearchIncompleteScreenshot,
    },
    {
      title: "Mobile optimized",
      description:
        "ToTheMax is fully optimized for mobile devices, allowing me to add items to my lists and check them off while on the go. This mobile-friendly design ensures I can manage my tasks and stay organized no matter where I am.",
      image: MobileOptimizedScreenshot, // Replace with the correct path to the screenshot
    },
  ];

  return (
    <main>
      <NavigationBar />
      <div className="hero-section">
        <div>
          <h1 id="website-title">ToTheMax</h1>
          <p id="website-tagline">My experience checklist management tool</p>
        </div>
      </div>
      <div className="container">
        <div>
          Frustrated by the lack of tools that perfectly suited my needs for
          tracking experiences and adventures, I decided to take matters into my
          own hands. I wanted a solution that could help me manage not just
          tasks, but experiences I wanted to check off, from visiting new places
          to trying out exciting activities. So, I set out to build ToTheMax, a
          personal checklist management tool tailored specifically to my
          lifestyle. Every single day, I use ToTheMax to keep track of all the
          unique experiences I want to complete, whether it’s discovering a new
          restaurant, attending a live show, or exploring a new city. It’s
          become an indispensable part of my life, allowing me to organize,
          plan, and reflect on my adventures like never before. Below, you'll
          find the features that make ToTheMax my go-to tool for managing my
          experiences and staying motivated.
        </div>
      </div>
      {features.map((feature, index) => (
        <div
          key={index}
          className={`feature-section ${
            index % 2 === 0 ? "right-image" : "left-image"
          }`}
        >
          <div className="feature-content container">
            <img
              src={feature.image}
              alt={feature.title}
              className="feature-image"
            />
            <div className="feature-description">
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
          </div>
        </div>
      ))}
      <Footer />
    </main>
  );
};

export default ToTheMaxPage;

export const Head = ({ pageContext }) => {
  const location = useLocation();
  return (
    <SEO
      index={true}
      follow={true}
      title={"ToTheMax"}
      pathname={location.pathname}
    />
  );
};
